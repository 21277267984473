import React, { useMemo } from 'react';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, NumberInput, Text, Stack } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import z from 'zod';
import { PaymentListFragment } from '../../graphql/graphql';
import { useRefundService } from '../../services/RefundService';
import { IconCurrencyDollar } from '@tabler/icons-react';
import CurrencyUtils from '@dr-pam/common-components/Utils/CurrencyUtils';
import { openConfirmModal } from '../modals/ConfirmModal';

export type IssueRefundModalProps = {
	modalId: string;
	onCancel?: () => void;
	onRefundComplete?: () => void;
	payment: PaymentListFragment;
};

export default function IssueRefundModal(props: IssueRefundModalProps) {
	const { modalId, onCancel, onRefundComplete, payment } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const refundService = useRefundService();

	const refundedAmount = useMemo(
		() => payment.refunds.reduce((acc, refund) => acc + refund.amountInCents, 0),
		[payment.refunds],
	);
	const nonrefundedAmount = useMemo(
		() => payment.amountInCents - refundedAmount,
		[payment.amountInCents, refundedAmount],
	);

	const validationSchema = useMemo(
		() =>
			z.object({
				amount: z.coerce
					.string()
					.nonempty('Refund amount is required')
					.pipe(
						z.coerce
							.number()
							.gte(0, 'Refund amount must be greater than (or equal to) 0')
							.lte(
								nonrefundedAmount / 100,
								'Refund amount must be less than or equal to the un-refunded amount',
							),
					),
			}),
		[nonrefundedAmount],
	);

	const form = useForm<RefundForm>({
		initialValues: {
			amount: nonrefundedAmount / 100,
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: RefundForm) => {
		const loader = addLoader();

		await openConfirmModal({
			title: 'Issue refund',
			danger: true,
			children: (
				<Text>
					Are you sure you want to issue a refund of {CurrencyUtils.format((values.amount || 0) * 100)} to{' '}
					{payment.user.displayName ?? payment.user.email}?
				</Text>
			),
			onConfirm: async () => {
				try {
					const request = refundService.issueRefund(payment.id, (values.amount || 0) * 100);
					await request.response;

					NotificationUtils.showSuccess('Successfully issued refund', 'Success');
					modals.close(modalId);

					onRefundComplete?.();
				} catch (err) {
					NotificationUtils.showError(err as Error, 'Failed to issue refund');
				}
			},
		});

		removeLoader(loader);
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<NumberInput
						value={(payment.amountInCents / 100).toFixed(2)}
						label="Charge amount"
						description="The amount originally charged to the user."
						decimalScale={2}
						decimalSeparator="."
						thousandSeparator=","
						disabled
						leftSection={<IconCurrencyDollar />}
					/>
					<NumberInput
						value={(refundedAmount / 100).toFixed(2)}
						label="Already refunded"
						description="The amount already refunded to user."
						decimalScale={2}
						decimalSeparator="."
						thousandSeparator=","
						disabled
						leftSection={<IconCurrencyDollar />}
					/>
					<NumberInput
						{...form.getInputProps('amount')}
						label="Refund amount"
						description="The amount to refund to the user."
						decimalScale={2}
						decimalSeparator="."
						thousandSeparator=","
						disabled={isLoading}
						leftSection={<IconCurrencyDollar />}
						withAsterisk
					/>
					<Group justify="flex-end" mt="md">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Issue refund {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type RefundForm = {
	amount: number | '';
};
