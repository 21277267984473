import React, { useCallback, useEffect, useState } from 'react';
import { TemplateListFragment, TemplateSingleFragment, TemplateType } from '../../graphql/graphql';
import { useTemplateService } from '../../services/TemplateService';
import MarkdownEditor from '../markdown/MarkdownEditor';
import { Breadcrumbs, Button, Container, Flex, Loader, Stack, TextInput, Title } from '@mantine/core';
import { IconDeviceFloppy, IconTrash } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { useMarkdownService } from '@dr-pam/markdown';
import { useTemplateRenderService } from '@dr-pam/common-components/Services/TemplateRenderService';
import { templateTypeMetadata } from '../../utils/TemplateUtils';
import TemplateTypeProductMenu from './TemplateTypeProductMenu';

export type EditTemplateProps = {
	templateType: TemplateType;
	productId?: string;
};

export default function EditTemplate(props: EditTemplateProps) {
	const { templateType, productId } = props;

	const [templateEntity, setTemplateEntity] = useState<TemplateSingleFragment | null>(null);
	const [templateContent, setTemplateContent] = useState<string>('');
	const [templateEmailSubject, setTemplateEmailSubject] = useState<string>('');
	const [templatesForType, setTemplatesForType] = useState<TemplateListFragment[]>([]);
	const [loading, setLoading] = useState(true);
	const [isChanged, setIsChanged] = useState(false);

	const templateService = useTemplateService();
	const markdownService = useMarkdownService();
	const templateRenderService = useTemplateRenderService(branding, markdownService);
	const navigate = useNavigate();

	const templateTypeInfo = templateTypeMetadata[templateType];

	const fetchTemplatesForType = useCallback(() => {
		if (!templateTypeInfo) {
			return;
		}
		const request = templateService.getAllByType(templateType);
		request.response
			.then((templates) => {
				setTemplatesForType(templates);
			})
			.catch(console.error);
		return () => {
			request.abort();
		};
	}, [templateService, templateType, templateTypeInfo]);

	useEffect(fetchTemplatesForType, [fetchTemplatesForType]);

	useEffect(() => {
		const request = templateService.getForTypeAndProduct(templateType, productId ?? null);
		setLoading(true);
		request.response
			.then((template) => {
				if (template) {
					setTemplateEntity(template);
					setTemplateContent(template.content);
					setTemplateEmailSubject(template.emailSubject ?? '');
				} else {
					setTemplateEntity(null);
					setTemplateContent('');
					setTemplateEmailSubject('');
				}
				setLoading(false);
			})
			.catch(console.error);
		return () => {
			request.abort();
		};
	}, [templateService, templateType, productId]);

	const saveTemplate = useCallback(async () => {
		let promise: Promise<TemplateSingleFragment>;
		if (templateEntity) {
			promise = templateService.update(templateEntity.id, {
				content: { set: templateContent },
				emailSubject: { set: templateEmailSubject.trim() },
			});
		} else {
			promise = templateService.create({
				type: templateType,
				product: productId ? { connect: { id: productId } } : undefined,
				content: templateContent,
				emailSubject: templateEmailSubject.trim(),
			});
		}
		try {
			const template = await promise;
			setTemplateEntity(template);
			setTemplateContent(template.content);
			setTemplateEmailSubject(template.emailSubject ?? '');
			notifications.show({
				title: 'Template Saved',
				message: 'The template has been successfully saved.',
				color: 'green',
			});
			fetchTemplatesForType();
			setIsChanged(false);
		} catch (error) {
			console.error(error);
			notifications.show({
				title: 'Error Saving Template',
				message: 'An error occurred while saving the template.',
				color: 'red',
			});
		}
	}, [
		fetchTemplatesForType,
		productId,
		templateContent,
		templateEmailSubject,
		templateEntity,
		templateService,
		templateType,
	]);

	const deleteTemplate = useCallback(async () => {
		if (!templateEntity) {
			return;
		}
		try {
			await templateService.delete(templateEntity.id);
			notifications.show({
				title: 'Template Deleted',
				message: 'The template has been successfully deleted.',
				color: 'green',
			});
			fetchTemplatesForType();
			navigate(`/templates/${templateType.toLowerCase()}`);
		} catch (error) {
			console.error(error);
			notifications.show({
				title: 'Error Deleting Template',
				message: 'An error occurred while deleting the template.',
				color: 'red',
			});
		}
	}, [fetchTemplatesForType, navigate, templateEntity, templateService, templateType]);

	const renderTemplate = useCallback(
		(text: string) => templateRenderService.compileAndRender(text, templatePreviewData),
		[templateRenderService],
	);

	const handleContentOnChange = useCallback((value: string) => {
		setTemplateContent(value);
		setIsChanged(true);
	}, []);

	const handleSubjectOnChange = useCallback((value: string) => {
		setTemplateEmailSubject(value);
		setIsChanged(true);
	}, []);

	return (
		<StyledContainer>
			<Breadcrumbs>
				<Link to="/templates">Templates</Link>
				<Link to="#">{templateTypeInfo.name}</Link>
			</Breadcrumbs>
			<Title order={1}>{templateTypeInfo.name}</Title>
			<StyledContent maw="100%">
				<TemplateTypeProductMenu
					templateType={templateType}
					productId={productId}
					templatesForType={templatesForType}
				/>
				{loading ? (
					<Loader />
				) : (
					<>
						<div>
							<Flex justify="space-between" style={{ marginBottom: 10 }}>
								<Button
									leftSection={<IconDeviceFloppy />}
									onClick={saveTemplate}
									color="blue"
									disabled={!isChanged}
								>
									Save
								</Button>
								{productId && templateEntity && (
									<Button leftSection={<IconTrash />} onClick={deleteTemplate} color="red">
										Delete
									</Button>
								)}
							</Flex>
							<TextInput
								label="Email subject"
								value={templateEmailSubject}
								onChange={(e) => handleSubjectOnChange(e.target.value)}
							/>
						</div>
						<MarkdownEditor
							initialValue={templateContent}
							onChange={handleContentOnChange}
							uploadFileNamePrefix="template"
							preRenderHook={renderTemplate}
						/>
					</>
				)}
			</StyledContent>
		</StyledContainer>
	);
}

const StyledContainer = styled(Stack)({
	display: 'grid',
	gridTemplateRows: 'auto auto 1fr',
	gridTemplateColumns: '1fr',
	overflow: 'hidden',
	width: '100%',
	height: '100%',
});

const StyledContent = styled(Container)({
	display: 'grid',
	gridTemplateAreas: '"templates actions" "templates editor"',
	gridTemplateRows: 'auto 1fr',
	gridTemplateColumns: 'auto 1fr',
	gap: 'var(--mantine-spacing-md)',
	overflow: 'hidden',
	width: '100%',
	height: '100%',
	padding: 0,
	margin: 0,
	'& > *:nth-child(1)': {
		gridArea: 'templates',
	},
	'& > *:nth-child(2)': {
		gridArea: 'actions',
	},
	'& > *:nth-child(3)': {
		gridArea: 'editor',
	},
});

const templatePreviewData = {
	customer: {
		name: 'Mary Smith',
	},
	resetUrl: 'https://example.com/reset',
	branding: {
		name: 'Example Brand',
	},
	event: {
		name: 'Example Event',
		date: '08-10-2024 07:00 PM',
		timezone: 'Australia/Brisbane',
		description: 'Example Event Description',
	},
	subscription: {
		name: 'Example Subscription',
		validTo: '08-10-2025',
		automaticRenew: true,
	},
};
const branding = new Map();
