import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';

import React, { useMemo } from 'react';
import AuthProvider from '@dr-pam/common-components/Components/Auth/AuthProvider';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import { createTheme, MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import ArticlesPage from '../pages/articles/ArticlesPage';
import DashboardPage from '../pages/dashboard/DashboardPage';
import EventsPage from '../pages/events/EventsPage';
import ProductsPage from '../pages/products/ProductsPage';
import ProgrammesPage from '../pages/programmes/ProgrammesPage';
import FinancesPage from '../pages/finances/FinancesPage';
import QuizzesPage from '../pages/quizzes/QuizzesPage';
import ResourcesPage from '../pages/resources/ResourcesPage';
import UsersPage from '../pages/users/UsersPage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import LogoutPage from '../pages/LogoutPage';
import ProfilePage from '../pages/profile/ProfilePage';
import ArticlePage from '../pages/articles/ArticlePage';
import QuizPage from '../pages/quizzes/QuizPage';
import ProgrammePage from '../pages/programmes/ProgrammePage';
import { HelmetProvider } from 'react-helmet-async';
import ProgrammesProvider from './programmes/ProgrammesProvider';
import LoginPage from '../pages/LoginPage';
import ProductPage from '../pages/products/ProductPage';
import UserPage from '../pages/users/UserPage';
import ExportPage from '../pages/export/ExportPage';
import EventPage from '../pages/events/EventPage';
import { DatesProvider } from '@mantine/dates';
import RuntimeGoogleTagManager from '@dr-pam/common-components/Components/Page/RuntimeGoogleTagManager';
import RuntimeConfigProvider from '@dr-pam/common-components/Config/RuntimeConfigProvider';
import ReportPage from '../pages/reports/ReportPage';
import EventRegistrationsPage from '../pages/events/EventRegistrationsPage';
import { SessionAuthService } from '@dr-pam/common-components/Services/SessionAuthService';
import CouponsPage from '../pages/coupons/CouponsPage';
import PodcastsPage from '../pages/podcasts/PodcastsPage';
import PodcastPage from '../pages/podcasts/PodcastPage';
import TemplatePage from '../pages/templates/TemplatePage';
import TemplatesPage from '../pages/templates/TemplatesPage';

const router = createBrowserRouter([
	{
		path: '/',
		element: <DashboardPage />,
	},
	{
		path: '/programmes',
		element: <ProgrammesPage />,
	},
	{
		path: '/programmes/:programmeId',
		element: <ProgrammePage />,
	},
	{
		path: '/articles',
		element: <ArticlesPage />,
	},
	{
		path: '/articles/:articleId',
		element: <ArticlePage />,
	},
	{
		path: '/article/:articleId/:slug?',
		element: <ArticlePage />,
	},
	{
		path: '/quizzes',
		element: <QuizzesPage />,
	},
	{
		path: '/quizzes/:quizId',
		element: <QuizPage />,
	},
	{
		path: '/resources',
		element: <ResourcesPage />,
	},
	{
		path: '/resource/:resourceId',
		element: <ResourcesPage />,
	},
	{
		path: '/resource/:resourceId/:slug?',
		element: <ResourcesPage />,
	},
	{
		path: '/events',
		element: <EventsPage />,
	},
	{
		path: '/events/:eventId',
		element: <EventPage />,
	},
	{
		path: '/events/:eventId/:eventOccurrenceId',
		element: <EventRegistrationsPage />,
	},
	{
		path: '/podcasts',
		element: <PodcastsPage />,
	},
	{
		path: '/podcasts/:podcastId',
		element: <PodcastPage />,
	},
	{
		path: '/products',
		element: <ProductsPage />,
	},
	{
		path: '/products/:productId',
		element: <ProductPage />,
	},
	{
		path: '/finances/:tab?',
		element: <FinancesPage />,
	},
	{
		path: '/coupons',
		element: <CouponsPage />,
	},
	{
		path: '/users',
		element: <UsersPage />,
	},
	{
		path: '/users/:userId',
		element: <UserPage />,
	},
	{
		path: '/export',
		element: <ExportPage />,
	},
	{
		path: 'reports',
		element: <ReportPage />,
	},
	{
		path: '/templates',
		element: <TemplatesPage />,
	},
	{
		path: '/templates/:templateTypeId/:productId?',
		element: <TemplatePage />,
	},
	{
		path: '/profile',
		element: <ProfilePage />,
	},
	{
		path: '/logout',
		element: <LogoutPage />,
	},
	{
		path: '/login',
		element: <LoginPage />,
	},
]);

const theme = createTheme({});
const authService = new SessionAuthService();
export default function App() {
	const apolloClient = useMemo(() => ApolloUtils.createApolloClient('/api/graphql'), []);

	return (
		<RuntimeConfigProvider>
			<RuntimeGoogleTagManager />

			<AuthProvider authService={authService} autoFetchUser>
				<ApolloProvider client={apolloClient}>
					<HelmetProvider>
						<MantineProvider theme={theme}>
							<DatesProvider settings={{}}>
								<ProgrammesProvider>
									<ModalsProvider>
										<Notifications />
										<RouterProvider router={router} />
									</ModalsProvider>
								</ProgrammesProvider>
							</DatesProvider>
						</MantineProvider>
					</HelmetProvider>
				</ApolloProvider>
			</AuthProvider>
		</RuntimeConfigProvider>
	);
}
