import React from 'react';
import BackendPage from '../../components/page/BackendPage';
import PaymentsList from '../../components/finances/PaymentsList';
import { Tabs } from '@mantine/core';
import { IconShoppingCartDollar, IconUserDollar } from '@tabler/icons-react';
import StringUtils from '@dr-pam/common-components/Utils/StringUtils';
import { useNavigate, useParams } from 'react-router-dom';
import RefundsList from '../../components/finances/RefundsList';

type Tabs = 'payments' | 'refunds';

export default function FinancesPage() {
	const params = useParams<{ tab?: Tabs }>();
	const navigate = useNavigate();

	const selectedTab = params.tab ?? 'payments';

	const handleTabChanged = (tab: string | null) => {
		if (!tab) {
			return;
		}

		navigate(`/finances/${tab}`);
	};
	return (
		<BackendPage title={`Finances - ${StringUtils.firstToUpperCase(selectedTab)}`}>
			<Tabs value={selectedTab} onChange={handleTabChanged}>
				<Tabs.List mb="md">
					<Tabs.Tab value="payments" leftSection={<IconShoppingCartDollar />}>
						Payments
					</Tabs.Tab>
					<Tabs.Tab value="refunds" leftSection={<IconUserDollar />}>
						Refunds
					</Tabs.Tab>
				</Tabs.List>
			</Tabs>
			{selectedTab === 'payments' && <PaymentsList />}
			{selectedTab === 'refunds' && <RefundsList />}
		</BackendPage>
	);
}
