import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import { useMemo } from 'react';
import ICrudService from '@dr-pam/common-components/Services/ICrudService';
import hash from 'object-hash';
import {
	QuizSingleFragment,
	QuizContentFragment,
	QuizListFragment,
	QuizCreateInput,
	QuizUpdateInput,
	QuizQuery,
	QuizQueryVariables,
	QuizzesQuery,
	SearchQuizzesQuery,
	SearchQuizzesQueryVariables,
	QuizWithContentQuery,
	QuizWithContentQueryVariables,
	CreateQuizMutation,
	CreateQuizMutationVariables,
	UpdateQuizMutation,
	UpdateQuizMutationVariables,
	DeleteQuizzesMutation,
	DeleteQuizzesMutationVariables,
	QuizzesByCategoryIdQuery,
	QuizzesByCategoryIdQueryVariables,
	RemoveQuizFromCategoryMutation,
	RemoveQuizFromCategoryMutationVariables,
	AddQuizToCategoryMutation,
	AddQuizToCategoryMutationVariables,
	QuizCategoryListFragment,
	UpdateQuizCategoryIsPinnedMutation,
	UpdateQuizCategoryIsPinnedMutationVariables,
	QuizUsageFragment,
	QuizUsagesQuery,
	QuizUsagesQueryVariables,
	UpdateQuizCategoryIsRequiredMutation,
	UpdateQuizCategoryIsRequiredMutationVariables,
	UpdateQuizCategoryRequirementsMutation,
	UpdateQuizCategoryRequirementsMutationVariables,
} from '../graphql/graphql';
import { SortableTreeItem } from '../models/SortableTreeItem';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import FetchUtils from '@dr-pam/common-components/Utils/FetchUtils';
import IPublishable from '@dr-pam/common-components/Services/IPublishable';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';

export type QuizWithContent = QuizSingleFragment & QuizContentFragment;

export class QuizSortableTreeItem<T extends QuizCategoryListFragment> extends SortableTreeItem<T> {
	public getId(): string {
		return this.item.id;
	}
	public getSortOrder(): number {
		return this.item.sortOrder;
	}
	public setSortOrder(sortOrder: number): void {
		this.item.sortOrder = sortOrder;
	}

	public getName(): string {
		if (this.parent) {
			return `${this.parent.getName()} > ${this.item.quiz.title}`;
		} else {
			return this.item.quiz.title;
		}
	}

	public getHash(): string {
		return hash([this.getId(), this.parent?.getId(), this.getSortOrder()]);
	}
}

export const GQL_FRAG_QUIZ_LIST = gql`
	fragment QuizList on Quiz {
		id
		title
		slug
		isPublished
		description
		content {
			id
			markdownHash
			publishedMarkdownHash
		}
	}
`;

export const GQL_FRAG_QUIZ_SINGLE = gql`
	fragment QuizSingle on Quiz {
		id
		created
		modified
		title
		slug
		isPublished
		description
	}
`;

export const GQL_FRAG_QUIZ_CONTENT = gql`
	fragment QuizContent on Quiz {
		content {
			id
			markdown
			markdownHash
			publishedMarkdownHash
		}
	}
`;

export const GQL_FRAG_QUIZ_CATEGORY_SINGLE = gql`
	${GQL_FRAG_QUIZ_LIST}
	fragment QuizCategorySingle on QuizCategory {
		id
		isPinned
		sortOrder
		isRequired
		maxAttempts
		minScore
		quiz {
			...QuizSingle
		}
	}
`;

export const GQL_FRAG_QUIZ_CATEGORY_LIST = gql`
	${GQL_FRAG_QUIZ_LIST}
	fragment QuizCategoryList on QuizCategory {
		id
		isPinned
		sortOrder
		isRequired
		maxAttempts
		minScore
		quiz {
			...QuizList
		}
	}
`;

export const GQL_GET_QUIZ = gql`
	${GQL_FRAG_QUIZ_SINGLE}
	query Quiz($quizId: String!) {
		quiz(where: { id: $quizId }) {
			...QuizSingle
		}
	}
`;

export const GQL_GET_QUIZZES_FOR_LIST = gql`
	${GQL_FRAG_QUIZ_LIST}
	query Quizzes {
		quizzes(orderBy: { created: desc }) {
			...QuizList
		}
	}
`;

export const GQL_SEARCH_QUIZZES_FOR_LIST = gql`
	${GQL_FRAG_QUIZ_LIST}
	query SearchQuizzes($title: String!) {
		quizzes(orderBy: { title: asc }, where: { title: { contains: $title, mode: insensitive } }) {
			...QuizList
		}
	}
`;

export const GQL_GET_QUIZZES_FOR_LIST_BY_CATEGORY = gql`
	${GQL_FRAG_QUIZ_LIST}
	query QuizzesByCategoryId($categoryIds: [String!]) {
		quizCategories(where: { categoryId: { in: $categoryIds } }) {
			quiz {
				...QuizList
			}
		}
	}
`;

export const GQL_GET_QUIZ_WITH_CONTENT = gql`
	${GQL_FRAG_QUIZ_SINGLE}
	${GQL_FRAG_QUIZ_CONTENT}
	query QuizWithContent($quizId: String!) {
		quiz(where: { id: $quizId }) {
			...QuizSingle
			...QuizContent
		}
	}
`;

export const GQL_GET_ORPHAN_QUIZZES = gql`
	${GQL_FRAG_QUIZ_LIST}
	query OrphanQuizzes {
		quizzes(where: { quizCategories: { none: { id: { startsWith: "" } } } }) {
			...QuizList
		}
	}
`;

export const GQL_GET_ARTICLE_USAGES = gql`
	fragment QuizUsage on QuizCategory {
		id
		category {
			id
			name
			programme {
				id
				name
			}
		}
	}

	query QuizUsages($quizId: String!) {
		quizCategories(where: { quizId: { equals: $quizId } }) {
			...QuizUsage
		}
	}
`;

export const GQL_DELETE_QUIZZES = gql`
	mutation DeleteQuizzes($quizIds: [String!]) {
		deleteManyQuiz(where: { id: { in: $quizIds } }) {
			count
		}
	}
`;

export const GQL_CREATE_QUIZ = gql`
	${GQL_FRAG_QUIZ_SINGLE}
	mutation CreateQuiz($quiz: QuizCreateInput!) {
		createOneQuiz(data: $quiz) {
			...QuizSingle
		}
	}
`;

export const GQL_UPDATE_QUIZ = gql`
	${GQL_FRAG_QUIZ_SINGLE}
	mutation UpdateQuiz($quizId: String!, $quiz: QuizUpdateInput!) {
		updateOneQuiz(where: { id: $quizId }, data: $quiz) {
			...QuizSingle
		}
	}
`;

export const GQL_ADD_QUIZ_TO_CATEGORY = gql`
	${GQL_FRAG_QUIZ_CATEGORY_LIST}
	mutation AddQuizToCategory($quizId: String!, $categoryId: String!, $sortOrder: Int) {
		upsertOneQuizCategory(
			where: { quizCategory: { quizId: $quizId, categoryId: $categoryId } }
			create: {
				quiz: { connect: { id: $quizId } }
				category: { connect: { id: $categoryId } }
				sortOrder: $sortOrder
			}
			update: {
				quiz: { connect: { id: $quizId } }
				category: { connect: { id: $categoryId } }
				sortOrder: { set: $sortOrder }
			}
		) {
			...QuizCategoryList
		}
	}
`;

export const GQL_REMOVE_QUIZ_FROM_CATEGORY = gql`
	mutation RemoveQuizFromCategory($quizId: String!, $categoryId: String!) {
		deleteOneQuizCategory(where: { quizCategory: { quizId: $quizId, categoryId: $categoryId } }) {
			id
		}
	}
`;

export const GQL_UPDATE_QUIZ_CATEGORY_IS_PINNED = gql`
	mutation UpdateQuizCategoryIsPinned($quizCategoryId: String!, $isPinned: Boolean!) {
		updateOneQuizCategory(where: { id: $quizCategoryId }, data: { isPinned: { set: $isPinned } }) {
			id
		}
	}
`;

export const GQL_UPDATE_QUIZ_CATEGORY_IS_REQUIRED = gql`
	mutation UpdateQuizCategoryIsRequired($quizCategoryId: String!, $isRequired: Boolean!) {
		updateOneQuizCategory(where: { id: $quizCategoryId }, data: { isRequired: { set: $isRequired } }) {
			id
		}
	}
`;

export const GQL_UPDATE_QUIZ_CATEGORY_REQUIREMENTS = gql`
	${GQL_FRAG_QUIZ_CATEGORY_LIST}
	mutation UpdateQuizCategoryRequirements($quizCategoryId: String!, $quizCategory: QuizCategoryUpdateInput!) {
		updateOneQuizCategory(where: { id: $quizCategoryId }, data: $quizCategory) {
			...QuizCategoryList
		}
	}
`;

export default class QuizService
	implements
		ICrudService<QuizListFragment, QuizSingleFragment, QuizCreateInput, QuizUpdateInput>,
		IPublishable<QuizListFragment>
{
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public async publish(quizId: string): Promise<QuizListFragment> {
		const request = FetchUtils.postJson<QuizListFragment>(`/api/quiz/${quizId}/publish`, {});

		const response = await request.response;

		return response;
	}

	public async unpublish(quizId: string): Promise<QuizListFragment> {
		const request = FetchUtils.postJson<QuizListFragment>(`/api/quiz/${quizId}/unpublish`, {});

		const response = await request.response;

		return response;
	}

	public get(quizId: string) {
		const request = ApolloUtils.abortableQuery<QuizQuery, QuizSingleFragment | null, QuizQueryVariables>(
			this._apolloClient,
			{
				query: GQL_GET_QUIZ,
				variables: {
					quizId,
				},
			},
			(data) => data.quiz ?? null,
			this._registerAbort,
		);

		return request;
	}

	public getAll() {
		const request = ApolloUtils.abortableQuery<QuizzesQuery, QuizListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_QUIZZES_FOR_LIST,
			},
			(data) => data.quizzes,
			this._registerAbort,
		);

		return request;
	}

	public getOrphans() {
		const request = ApolloUtils.abortableQuery<QuizzesQuery, QuizListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_ORPHAN_QUIZZES,
			},
			(data) => data.quizzes,
			this._registerAbort,
		);

		return request;
	}

	public searchByTitle(title: string) {
		const request = ApolloUtils.abortableQuery<SearchQuizzesQuery, QuizListFragment[], SearchQuizzesQueryVariables>(
			this._apolloClient,
			{
				query: GQL_SEARCH_QUIZZES_FOR_LIST,
				variables: {
					title,
				},
			},
			(data) => data.quizzes,
			this._registerAbort,
		);

		return request;
	}

	public getWithContent(quizId: string) {
		const request = ApolloUtils.abortableQuery<
			QuizWithContentQuery,
			QuizWithContent | null,
			QuizWithContentQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_QUIZ_WITH_CONTENT,
				variables: {
					quizId,
				},
			},
			(data) => data.quiz ?? null,
			this._registerAbort,
		);

		return request;
	}

	public async create(quiz: QuizCreateInput) {
		const result = await this._apolloClient.mutate<CreateQuizMutation, CreateQuizMutationVariables>({
			mutation: GQL_CREATE_QUIZ,
			variables: {
				quiz,
			},
		});
		if (!result.data?.createOneQuiz) {
			throw new Error('Failed to create quiz');
		}
		return result.data.createOneQuiz;
	}

	public async update(quizId: string, quiz: QuizUpdateInput) {
		const result = await this._apolloClient.mutate<UpdateQuizMutation, UpdateQuizMutationVariables>({
			mutation: GQL_UPDATE_QUIZ,
			variables: {
				quizId,
				quiz,
			},
		});
		if (!result.data?.updateOneQuiz) {
			throw new Error('Failed to update quiz');
		}
		return result.data.updateOneQuiz;
	}

	public async delete(quizIds: string | string[]) {
		quizIds = Array.isArray(quizIds) ? quizIds : [quizIds];

		await this._apolloClient.mutate<DeleteQuizzesMutation, DeleteQuizzesMutationVariables>({
			mutation: GQL_DELETE_QUIZZES,
			variables: {
				quizIds,
			},
		});
	}

	public getAllByCategoryId(categoryIds: string | string[]) {
		if (typeof categoryIds === 'string') {
			categoryIds = [];
		}

		const request = ApolloUtils.abortableQuery<
			QuizzesByCategoryIdQuery,
			QuizListFragment[],
			QuizzesByCategoryIdQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_QUIZZES_FOR_LIST_BY_CATEGORY,
				variables: {
					categoryIds,
				},
			},
			(data) => data.quizCategories.map((ac) => ac.quiz),
			this._registerAbort,
		);

		return request;
	}

	public getAllUsages(quizId: string) {
		const request = ApolloUtils.abortableQuery<QuizUsagesQuery, QuizUsageFragment[], QuizUsagesQueryVariables>(
			this._apolloClient,
			{
				query: GQL_GET_ARTICLE_USAGES,
				variables: {
					quizId,
				},
			},
			(data) => data.quizCategories,
			this._registerAbort,
		);

		return request;
	}

	public async addQuizToCategory(
		quizId: string,
		categoryId: string,
		sortOrder?: number,
	): Promise<QuizCategoryListFragment> {
		const result = await this._apolloClient.mutate<AddQuizToCategoryMutation, AddQuizToCategoryMutationVariables>({
			mutation: GQL_ADD_QUIZ_TO_CATEGORY,
			variables: {
				quizId,
				categoryId,
				sortOrder: sortOrder ?? 0,
			},
		});
		if (!result.data?.upsertOneQuizCategory) {
			throw new Error('Failed to add quiz to category');
		}
		return result.data.upsertOneQuizCategory;
	}

	public async removeQuizFromCategory(quizId: string, categoryId: string): Promise<void> {
		const result = await this._apolloClient.mutate<
			RemoveQuizFromCategoryMutation,
			RemoveQuizFromCategoryMutationVariables
		>({
			mutation: GQL_REMOVE_QUIZ_FROM_CATEGORY,
			variables: {
				quizId,
				categoryId,
			},
		});
		if (!result.data?.deleteOneQuizCategory) {
			throw new Error('Failed to remove quiz from category');
		}
	}

	public async updateIsPinned(quizCategoryId: string, isPinned: boolean) {
		const result = await this._apolloClient.mutate<
			UpdateQuizCategoryIsPinnedMutation,
			UpdateQuizCategoryIsPinnedMutationVariables
		>({
			mutation: GQL_UPDATE_QUIZ_CATEGORY_IS_PINNED,
			variables: {
				quizCategoryId,
				isPinned,
			},
		});
		if (!result.data?.updateOneQuizCategory) {
			throw new Error('Failed to update quiz category');
		}
		return result.data.updateOneQuizCategory;
	}

	public async updateIsRequired(quizCategoryId: string, isRequired: boolean) {
		const result = await this._apolloClient.mutate<
			UpdateQuizCategoryIsRequiredMutation,
			UpdateQuizCategoryIsRequiredMutationVariables
		>({
			mutation: GQL_UPDATE_QUIZ_CATEGORY_IS_REQUIRED,
			variables: {
				quizCategoryId,
				isRequired,
			},
		});
		if (!result.data?.updateOneQuizCategory) {
			throw new Error('Failed to update quiz category');
		}
		return result.data.updateOneQuizCategory;
	}

	public async updateRequirements(
		quizCategoryId: string,
		quizCategory: Pick<QuizCategoryListFragment, 'minScore' | 'maxAttempts'>,
	) {
		const result = await this._apolloClient.mutate<
			UpdateQuizCategoryRequirementsMutation,
			UpdateQuizCategoryRequirementsMutationVariables
		>({
			mutation: GQL_UPDATE_QUIZ_CATEGORY_REQUIREMENTS,
			variables: {
				quizCategoryId,
				quizCategory: {
					minScore: { set: quizCategory.minScore },
					maxAttempts: { set: quizCategory.maxAttempts },
				},
			},
		});
		if (!result.data?.updateOneQuizCategory) {
			throw new Error('Failed to update quiz category');
		}
		return result.data.updateOneQuizCategory;
	}

	public duplicateQuiz(quizId: string) {
		return FetchUtils.postJson<QuizListFragment>(`/api/quiz/${quizId}/duplicate`, {});
	}
}

export function useQuizService() {
	const apolloClient = useApolloClient();
	const registerAbort = useAbortRegistry();

	return useMemo(() => new QuizService(apolloClient, registerAbort), [apolloClient, registerAbort]);
}
