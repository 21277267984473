import { Button, Flex, Popover, ScrollArea, Tooltip } from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';
import { ProductListFragment, TemplateListFragment, TemplateType } from '../../graphql/graphql';
import NavigationLink from '../page/NavigationLink';
import { IconPlus } from '@tabler/icons-react';
import { useProductService } from '../../services/ProductService';
import { templateTypeMetadata } from '../../utils/TemplateUtils';

export type TemplateTypeProductMenuProps = {
	templateType: TemplateType;
	productId?: string;
	templatesForType: TemplateListFragment[];
};

export default function TemplateTypeProductMenu(props: TemplateTypeProductMenuProps) {
	const { templateType, productId, templatesForType } = props;

	const templateMetadata = templateTypeMetadata[templateType];
	const perProduct = templateMetadata?.perProductEnabled ?? false;

	const [products, setProducts] = useState<ProductListFragment[]>([]);
	const [addTemplatePopoverOpen, setAddTemplatePopoverOpen] = useState(false);

	const productService = useProductService();

	useEffect(() => {
		const request = productService.getAll();
		request.response
			.then((products) => {
				setProducts(products);
			})
			.catch(console.error);
		return () => {
			request.abort();
		};
	}, [productService]);

	const templates = useMemo(
		() =>
			templatesForType
				.filter((x) => x.product)
				// Sort alphabetically by product name
				.toSorted((a, b) => ('' + a.product?.name).localeCompare('' + b.product?.name)) ?? [],
		[templatesForType],
	);
	const remainingProducts = useMemo(
		() =>
			products.filter(
				(product) =>
					product.id !== productId && !templates.some((template) => template.product?.id === product.id),
			),
		[products, productId, templates],
	);

	// If productId matches a product that does not yet have a template,
	// add a placeholder template for that product
	const placeholder = useMemo(() => {
		if (productId && templates.every((template) => template.product?.id !== productId)) {
			const product = products.find((product) => product.id === productId);
			if (product) {
				return {
					id: product.id,
					name: product.name,
				};
			}
		}
		return null;
	}, [productId, templates, products]);

	useEffect(() => {
		setAddTemplatePopoverOpen(false);
	}, [productId]);

	const basePath = `/templates/${templateType.toLowerCase()}`;

	return (
		<ScrollArea>
			<Flex direction="column" gap="md" style={{ width: 200 }}>
				<NavigationLink link={{ id: 'default', label: 'Default', link: basePath, exactMatch: true }} />
				{perProduct &&
					templates.map((template) => (
						<NavigationLink
							key={template.id}
							link={{
								id: template.id,
								label: template.product?.name,
								link: `${basePath}/${template.product?.id}`,
							}}
						/>
					))}
				{perProduct && placeholder && (
					<NavigationLink
						link={{
							id: placeholder.id,
							label: placeholder.name,
							link: `${basePath}/${placeholder.id}`,
						}}
					/>
				)}
				<Popover
					width={300}
					position="bottom"
					shadow="md"
					opened={addTemplatePopoverOpen}
					onChange={setAddTemplatePopoverOpen}
				>
					<Popover.Target>
						<Tooltip
							label={perProduct ? 'Add template' : 'This template cannot be customized per product'}
							position="bottom"
							withArrow
						>
							<Button
								leftSection={<IconPlus />}
								variant="subtle"
								fullWidth
								onClick={() => setAddTemplatePopoverOpen((o) => !o)}
								disabled={!perProduct}
							>
								Add template
							</Button>
						</Tooltip>
					</Popover.Target>
					<Popover.Dropdown>
						<ScrollArea h={400} type="always">
							{remainingProducts.map((product) => (
								<NavigationLink
									key={product.id}
									link={{
										id: product.id,
										label: product.name,
										link: `${basePath}/${product.id}`,
									}}
								/>
							))}
						</ScrollArea>
					</Popover.Dropdown>
				</Popover>
			</Flex>
		</ScrollArea>
	);
}
