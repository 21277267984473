import React from 'react';
import useComputedSlug from '../../hooks/useComputedSlug';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, Select, Stack, TextInput, Textarea } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { AddProductForm } from './AddProductModal';
import { useProductService } from '../../services/ProductService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { CrudEditModalProps } from '../crud/CrudTable';
import { BrandingType, ProductListFragment, ProductSingleFragment } from '../../graphql/graphql';
import z from 'zod';

export const validationSchema = z.object({
	name: z.string().nonempty().max(512),
	shortName: z.string().nonempty().max(512),
	slug: z.string().nonempty().max(512),
	brandingType: z.nativeEnum(BrandingType),
	description: z.string().nonempty(),
});

export type EditProductModalProps = CrudEditModalProps<ProductListFragment, ProductSingleFragment>;

export default function EditProductModal(props: EditProductModalProps) {
	const { modalId, current, onCancel, onEdited } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const productService = useProductService();

	const form = useForm<EditProductForm>({
		initialValues: {
			name: current.name,
			shortName: current.shortName,
			slug: current.slug,
			brandingType: current.brandingType,
			description: current.description,
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: EditProductForm) => {
		const loader = addLoader();

		try {
			const updatedProduct = await productService.update(current.id, {
				name: { set: values.name },
				shortName: { set: values.shortName },
				slug: { set: values.slug },
				brandingType: { set: values.brandingType },
				description: { set: values.description },
			});

			onEdited(updatedProduct);
			NotificationUtils.showSuccess('Successfully updated product', updatedProduct.name);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to update product');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	const slugComputedFromProps = useComputedSlug(form, 'slug', 'name');

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)} method="post" action="/api/products">
				<Stack>
					<TextInput
						{...slugComputedFromProps}
						label="Name"
						description="The name of the product. Forms the basis of the Slug."
						disabled={isLoading}
						withAsterisk
					/>
					<TextInput
						{...form.getInputProps('shortName')}
						label="Short name"
						description="The name to display when space is limited."
						disabled={isLoading}
						withAsterisk
					/>
					<Textarea
						{...form.getInputProps('description')}
						label="Description"
						description="The description of the product."
						disabled={isLoading}
						withAsterisk
						rows={5}
					/>
					<TextInput
						{...form.getInputProps('slug')}
						label="Slug"
						description="This will appear in the browser URL."
						disabled={isLoading}
						withAsterisk
					/>
					<Select
						{...form.getInputProps('brandingType')}
						label="Branding"
						description="The branding used for this product."
						disabled={isLoading}
						withAsterisk
						data={Object.values(BrandingType)}
					/>
					<Group justify="flex-end">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Save product {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type EditProductForm = AddProductForm;
