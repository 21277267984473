import { useMemo } from 'react';
import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import ICrudService from '@dr-pam/common-components/Services/ICrudService';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import {
	ProductQuery,
	ProductSingleFragment,
	ProductQueryVariables,
	ProductsQuery,
	ProductListFragment,
	ProductCreateInput,
	CreateProductMutation,
	CreateProductMutationVariables,
	ProductUpdateInput,
	UpdateProductMutation,
	UpdateProductMutationVariables,
	DeleteProductsMutation,
	DeleteProductsMutationVariables,
	SearchProductsQuery,
	SearchProductsQueryVariables,
	ProductWithProgrammesAndSubscriptionTypesQuery,
	ProductWithProgrammesAndSubscriptionTypesQueryVariables,
	AddProductProgrammeMutation,
	AddProductProgrammeMutationVariables,
	ProductProgrammeFragment,
	DeleteProductProgrammeMutation,
	DeleteProductProgrammeMutationVariables,
	ProductSubscriptionTypeCreateInput,
	CreateProductSubscriptionTypeMutation,
	CreateProductSubscriptionTypeMutationVariables,
	UpdateProductSubscriptionTypeMutation,
	UpdateProductSubscriptionTypeMutationVariables,
	ProductSubscriptionTypeUpdateInput,
	DeleteProductSubscriptionTypesMutation,
	DeleteProductSubscriptionTypesMutationVariables,
	ProductProgrammePrerequisiteFragment,
	DeleteProductPrerequisitesMutation,
	DeleteProductPrerequisitesMutationVariables,
} from '../graphql/graphql';
import { GQL_FRAG_PROGRAMME_LIST } from './ProgrammeService';
import IPublishable from '@dr-pam/common-components/Services/IPublishable';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';
import FetchUtils from '@dr-pam/common-components/Utils/FetchUtils';

export type ProductWithProgrammesAndSubscriptionTypes = NonNullable<
	ProductWithProgrammesAndSubscriptionTypesQuery['product']
>;

export type ProductProgramme = NonNullable<ProductWithProgrammesAndSubscriptionTypes['productProgrammes']>[0];
export type ProductSubscriptionType = NonNullable<
	ProductWithProgrammesAndSubscriptionTypes['productSubscriptionTypes']
>[0];

export const GQL_FRAG_PRODUCT_LIST = gql`
	fragment ProductList on Product {
		id
		name
		shortName
		description
		slug
		isPublished
		brandingType
	}
`;

export const GQL_FRAG_PRODUCT_SINGLE = gql`
	fragment ProductSingle on Product {
		id
		created
		modified
		name
		shortName
		description
		slug
		isPublished
		brandingType
	}
`;

export const GQL_FRAG_PRODUCT_PROGRAMME = gql`
	${GQL_FRAG_PROGRAMME_LIST}
	fragment ProductProgramme on ProductProgramme {
		id
		productId
		programmeId
		programme {
			...ProgrammeList
		}
	}
`;

export const GQL_FRAG_PRODUCT_SUBSCRIPTION_TYPE_LIST = gql`
	fragment ProductSubscriptionTypeList on ProductSubscriptionType {
		id
		name
		description
		isPublished
		automaticRenew
		durationInDays
		priceInCents
		xeroAccountCode
	}
`;

export const GQL_FRAG_PRODUCT_SUBSCRIPTION_TYPE_SINGLE = gql`
	fragment ProductSubscriptionTypeSingle on ProductSubscriptionType {
		id
		name
		description
		isPublished
		automaticRenew
		durationInDays
		priceInCents
		xeroAccountCode
	}
`;

export const GQL_FRAG_PRODUCT_PROGRAMME_PREREQUISITE = gql`
	fragment ProductProgrammePrerequisite on ProductProgrammePrerequisite {
		id
		productId
		programmeId
		programme {
			isPublished
			name
		}
	}
`;

export const GQL_GET_PRODUCT = gql`
	${GQL_FRAG_PRODUCT_SINGLE}
	query Product($productId: String!) {
		product(where: { id: $productId }) {
			...ProductSingle
		}
	}
`;

export const GQL_GET_PRODUCT_WITH_PROGRAMMES_AND_SUBSCRIPTION_TYPES = gql`
	${GQL_FRAG_PRODUCT_SINGLE}
	${GQL_FRAG_PRODUCT_PROGRAMME}
	${GQL_FRAG_PRODUCT_SUBSCRIPTION_TYPE_LIST}
	${GQL_FRAG_PRODUCT_PROGRAMME_PREREQUISITE}
	query ProductWithProgrammesAndSubscriptionTypes($productId: String!) {
		product(where: { id: $productId }) {
			...ProductSingle
			productProgrammes {
				...ProductProgramme
			}
			productSubscriptionTypes {
				...ProductSubscriptionTypeList
			}
			prerequisites {
				...ProductProgrammePrerequisite
			}
		}
	}
`;

export const GQL_GET_PRODUCTS_FOR_LIST = gql`
	${GQL_FRAG_PRODUCT_LIST}
	query Products {
		products(orderBy: { name: asc }) {
			...ProductList
		}
	}
`;

export const GQL_SEARCH_PRODUCTS_FOR_LIST = gql`
	${GQL_FRAG_PRODUCT_LIST}
	query SearchProducts($name: String!, $excludeIds: [String!]) {
		products(
			orderBy: { name: asc }
			where: { name: { contains: $name, mode: insensitive }, id: { notIn: $excludeIds } }
		) {
			...ProductList
		}
	}
`;

export const GQL_DELETE_PRODUCTS = gql`
	mutation DeleteProducts($productIds: [String!]) {
		deleteManyProduct(where: { id: { in: $productIds } }) {
			count
		}
	}
`;

export const GQL_CREATE_PRODUCT = gql`
	${GQL_FRAG_PRODUCT_SINGLE}
	mutation CreateProduct($product: ProductCreateInput!) {
		createOneProduct(data: $product) {
			...ProductSingle
		}
	}
`;

export const GQL_UPDATE_PRODUCT = gql`
	${GQL_FRAG_PRODUCT_SINGLE}
	mutation UpdateProduct($productId: String!, $product: ProductUpdateInput!) {
		updateOneProduct(where: { id: $productId }, data: $product) {
			...ProductSingle
		}
	}
`;

export const GQL_ADD_PRODUCT_PROGRAMME = gql`
	${GQL_FRAG_PRODUCT_PROGRAMME}
	mutation AddProductProgramme($productId: String!, $programmeId: String!) {
		createOneProductProgramme(
			data: { product: { connect: { id: $productId } }, programme: { connect: { id: $programmeId } } }
		) {
			...ProductProgramme
		}
	}
`;

export const GQL_DELETE_PRODUCT_PROGRAMME = gql`
	mutation DeleteProductProgramme($productId: String!, $programmeId: String!) {
		deleteOneProductProgramme(where: { productProgramme: { productId: $productId, programmeId: $programmeId } }) {
			id
		}
	}
`;

export const GQL_DELETE_PRODUCT_PREREQUISITES = gql`
	mutation DeleteProductPrerequisites($prerequisiteIds: [String!]) {
		deleteManyProductProgrammePrerequisite(where: { id: { in: $prerequisiteIds } }) {
			count
		}
	}
`;

export const GQL_DELETE_PRODUCT_SUBSCRIPTION_TYPES = gql`
	mutation DeleteProductSubscriptionTypes($productSubscriptionTypeIds: [String!]) {
		deleteManyProductSubscriptionType(where: { id: { in: $productSubscriptionTypeIds } }) {
			count
		}
	}
`;

export const GQL_CREATE_PRODUCT_SUBSCRIPTION_TYPE = gql`
	${GQL_FRAG_PRODUCT_SUBSCRIPTION_TYPE_SINGLE}
	mutation CreateProductSubscriptionType($productSubscriptionType: ProductSubscriptionTypeCreateInput!) {
		createOneProductSubscriptionType(data: $productSubscriptionType) {
			...ProductSubscriptionTypeSingle
		}
	}
`;

export const GQL_UPDATE_PRODUCT_SUBSCRIPTION_TYPE = gql`
	${GQL_FRAG_PRODUCT_SUBSCRIPTION_TYPE_SINGLE}
	mutation UpdateProductSubscriptionType(
		$productSubscriptionTypeId: String!
		$productSubscriptionType: ProductSubscriptionTypeUpdateInput!
	) {
		updateOneProductSubscriptionType(where: { id: $productSubscriptionTypeId }, data: $productSubscriptionType) {
			...ProductSubscriptionTypeSingle
		}
	}
`;

const runtimeGql = gql;

export default class ProductService implements ICrudService, IPublishable {
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public async publish(productId: string): Promise<ProductListFragment> {
		const request = FetchUtils.postJson<ProductListFragment>(`/api/product/${productId}/publish`, {});

		const response = await request.response;

		return response;
	}

	public async unpublish(productId: string): Promise<ProductListFragment> {
		const request = FetchUtils.postJson<ProductListFragment>(`/api/product/${productId}/unpublish`, {});

		const response = await request.response;

		return response;
	}

	public get(productId: string) {
		const request = ApolloUtils.abortableQuery<ProductQuery, ProductSingleFragment | null, ProductQueryVariables>(
			this._apolloClient,
			{
				query: GQL_GET_PRODUCT,
				variables: {
					productId,
				},
			},
			(data) => data.product ?? null,
			this._registerAbort,
		);

		return request;
	}

	public getWithProgrammesAndSubscriptionTypes(productId: string) {
		const request = ApolloUtils.abortableQuery<
			ProductWithProgrammesAndSubscriptionTypesQuery,
			ProductWithProgrammesAndSubscriptionTypes | null,
			ProductWithProgrammesAndSubscriptionTypesQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_PRODUCT_WITH_PROGRAMMES_AND_SUBSCRIPTION_TYPES,
				variables: {
					productId,
				},
			},
			(data) => data.product ?? null,
			this._registerAbort,
		);

		return request;
	}

	public async addProgrammes(productId: string, programmeIds: string[]): Promise<ProductProgrammeFragment[]> {
		const mutation = runtimeGql`
			${GQL_FRAG_PRODUCT_PROGRAMME}
			mutation AddProductProgrammes($productId: String!) {
				${programmeIds.map(
					(programmeId, i) => `
					data${i}: createOneProductProgramme(
						data: { product: { connect: { id: $productId } }, programme: { connect: { id: "${programmeId}" } } }
					) {
						...ProductProgramme
					}
				`,
				)}
			}
		`;
		const result = await this._apolloClient.mutate({
			mutation,
			variables: {
				productId,
			},
		});
		if (!result.data) {
			throw new Error('Failed to create product programmes');
		}
		return Object.values(result.data);
	}

	public async addProgramme(productId: string, programmeId: string): Promise<ProductProgrammeFragment | null> {
		const result = await this._apolloClient.mutate<
			AddProductProgrammeMutation,
			AddProductProgrammeMutationVariables
		>({
			mutation: GQL_ADD_PRODUCT_PROGRAMME,
			variables: {
				productId,
				programmeId,
			},
		});

		return result.data?.createOneProductProgramme ?? null;
	}

	public async addPrerequisites(
		productId: string,
		programmeIds: string[],
	): Promise<ProductProgrammePrerequisiteFragment[]> {
		const mutation = runtimeGql`
			${GQL_FRAG_PRODUCT_PROGRAMME_PREREQUISITE}
			mutation AddProductProgrammes($productId: String!) {
				${programmeIds.map(
					(programmeId, i) => `
					data${i}: createOneProductProgrammePrerequisite(
						data: { product: { connect: { id: $productId } }, programme: { connect: { id: "${programmeId}" } } }
					) {
						...ProductProgrammePrerequisite
					}
				`,
				)}
			}
		`;
		const result = await this._apolloClient.mutate({
			mutation,
			variables: {
				productId,
			},
		});
		if (!result.data) {
			throw new Error('Failed to create product programmes');
		}
		return Object.values(result.data);
	}

	public async removePrerequisites(prerequisiteIds: string | string[]) {
		prerequisiteIds = Array.isArray(prerequisiteIds) ? prerequisiteIds : [prerequisiteIds];

		await this._apolloClient.mutate<
			DeleteProductPrerequisitesMutation,
			DeleteProductPrerequisitesMutationVariables
		>({
			mutation: GQL_DELETE_PRODUCT_PREREQUISITES,
			variables: {
				prerequisiteIds,
			},
		});
	}

	public async removeProgramme(productId: string, programmeId: string) {
		await this._apolloClient.mutate<DeleteProductProgrammeMutation, DeleteProductProgrammeMutationVariables>({
			mutation: GQL_DELETE_PRODUCT_PROGRAMME,
			variables: {
				productId,
				programmeId,
			},
		});
	}

	public async addProductSubscriptionType(productSubscriptionType: ProductSubscriptionTypeCreateInput) {
		const result = await this._apolloClient.mutate<
			CreateProductSubscriptionTypeMutation,
			CreateProductSubscriptionTypeMutationVariables
		>({
			mutation: GQL_CREATE_PRODUCT_SUBSCRIPTION_TYPE,
			variables: {
				productSubscriptionType,
			},
		});
		if (!result.data?.createOneProductSubscriptionType) {
			throw new Error('Failed to create product subscription type');
		}
		return result.data.createOneProductSubscriptionType;
	}

	public async updateProductSubscriptionType(
		productSubscriptionTypeId: string,
		productSubscriptionType: ProductSubscriptionTypeUpdateInput,
	) {
		const result = await this._apolloClient.mutate<
			UpdateProductSubscriptionTypeMutation,
			UpdateProductSubscriptionTypeMutationVariables
		>({
			mutation: GQL_UPDATE_PRODUCT_SUBSCRIPTION_TYPE,
			variables: {
				productSubscriptionTypeId,
				productSubscriptionType,
			},
		});
		if (!result.data?.updateOneProductSubscriptionType) {
			throw new Error('Failed to save product subscription type');
		}
		return result.data.updateOneProductSubscriptionType;
	}

	public async deleteProductSubscriptionType(productSubscriptionTypeIds: string | string[]) {
		productSubscriptionTypeIds = Array.isArray(productSubscriptionTypeIds)
			? productSubscriptionTypeIds
			: [productSubscriptionTypeIds];

		await this._apolloClient.mutate<
			DeleteProductSubscriptionTypesMutation,
			DeleteProductSubscriptionTypesMutationVariables
		>({
			mutation: GQL_DELETE_PRODUCT_SUBSCRIPTION_TYPES,
			variables: {
				productSubscriptionTypeIds,
			},
		});
	}

	// public removeSubscriptionType(productId: string, subscriptionTypeId: SubscriptionTypeSingle) {}

	public getAll() {
		const request = ApolloUtils.abortableQuery<ProductsQuery, ProductListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_PRODUCTS_FOR_LIST,
			},
			(data) => data.products,
			this._registerAbort,
		);

		return request;
	}

	public searchByName(name: string, excludeIds?: string[]) {
		const request = ApolloUtils.abortableQuery<
			SearchProductsQuery,
			ProductListFragment[],
			SearchProductsQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_SEARCH_PRODUCTS_FOR_LIST,
				variables: {
					name,
					excludeIds: excludeIds ?? [],
				},
			},
			(data) => data.products,
			this._registerAbort,
		);

		return request;
	}

	public async create(product: ProductCreateInput) {
		const result = await this._apolloClient.mutate<CreateProductMutation, CreateProductMutationVariables>({
			mutation: GQL_CREATE_PRODUCT,
			variables: {
				product,
			},
		});
		if (!result.data?.createOneProduct) {
			throw new Error('Failed to create product');
		}
		return result.data.createOneProduct;
	}

	public async update(productId: string, product: ProductUpdateInput) {
		const result = await this._apolloClient.mutate<UpdateProductMutation, UpdateProductMutationVariables>({
			mutation: GQL_UPDATE_PRODUCT,
			variables: {
				productId,
				product,
			},
		});
		if (!result.data?.updateOneProduct) {
			throw new Error('Failed to update product');
		}
		return result.data.updateOneProduct;
	}

	public async delete(productIds: string | string[]) {
		productIds = Array.isArray(productIds) ? productIds : [productIds];

		await this._apolloClient.mutate<DeleteProductsMutation, DeleteProductsMutationVariables>({
			mutation: GQL_DELETE_PRODUCTS,
			variables: {
				productIds,
			},
		});
	}
}

export function useProductService() {
	const apolloClient = useApolloClient();

	return useMemo(() => new ProductService(apolloClient), [apolloClient]);
}
