import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import ICrudService from '@dr-pam/common-components/Services/ICrudService';
import {
	TemplateListFragment,
	TemplateQuery,
	TemplateSingleFragment,
	TemplateQueryVariables,
	TemplatesQuery,
	TemplateCreateInput,
	TemplateUpdateInput,
	CreateTemplateMutation,
	CreateTemplateMutationVariables,
	UpdateTemplateMutation,
	UpdateTemplateMutationVariables,
	DeleteTemplatesMutation,
	DeleteTemplatesMutationVariables,
	TemplateType,
	TemplatesForTypeQuery,
	TemplateForTypeAndProductQuery,
	TemplateForTypeAndProductQueryVariables,
} from '../graphql/graphql';
import { AbortableRequest } from '@dr-pam/common-components/Utils/FetchUtils';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';
import { useMemo } from 'react';

export const GQL_FRAG_TEMPLATE_LIST = gql`
	fragment TemplateList on Template {
		id
		type
		product {
			id
			name
		}
	}
`;

export const GQL_FRAG_TEMPLATE_SINGLE = gql`
	fragment TemplateSingle on Template {
		id
		created
		modified
		type
		product {
			id
			name
		}
		content
		emailSubject
		emailPreviewText
	}
`;

export const GQL_GET_TEMPLATE = gql`
	${GQL_FRAG_TEMPLATE_SINGLE}
	query Template($templateId: String!) {
		template(where: { id: $templateId }) {
			...TemplateSingle
		}
	}
`;

export const GQL_GET_TEMPLATE_FOR_TYPE_AND_PRODUCT = gql`
	${GQL_FRAG_TEMPLATE_SINGLE}
	query TemplateForTypeAndProduct($templateType: TemplateType!, $productId: String) {
		findFirstTemplate(
			where: { AND: [{ type: { equals: $templateType } }, { productId: { equals: $productId } }] }
		) {
			...TemplateSingle
		}
	}
`;

export const GQL_GET_TEMPLATES_FOR_LIST = gql`
	${GQL_FRAG_TEMPLATE_LIST}
	query Templates {
		templates {
			...TemplateList
		}
	}
`;

export const GQL_GET_TEMPLATES_FOR_TYPE = gql`
	${GQL_FRAG_TEMPLATE_LIST}
	query TemplatesForType($templateType: TemplateType!) {
		templates(where: { type: { equals: $templateType } }) {
			...TemplateList
		}
	}
`;

export const GQL_CREATE_TEMPLATE = gql`
	${GQL_FRAG_TEMPLATE_SINGLE}
	mutation CreateTemplate($template: TemplateCreateInput!) {
		createOneTemplate(data: $template) {
			...TemplateSingle
		}
	}
`;

export const GQL_UPDATE_TEMPLATE = gql`
	${GQL_FRAG_TEMPLATE_SINGLE}
	mutation UpdateTemplate($templateId: String!, $template: TemplateUpdateInput!) {
		updateOneTemplate(where: { id: $templateId }, data: $template) {
			...TemplateSingle
		}
	}
`;

export const GQL_DELETE_TEMPLATES = gql`
	mutation DeleteTemplates($templateIds: [String!]) {
		deleteManyTemplate(where: { id: { in: $templateIds } }) {
			count
		}
	}
`;

export default class TemplateService
	implements ICrudService<TemplateListFragment, TemplateSingleFragment, TemplateCreateInput, TemplateUpdateInput>
{
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public get(templateId: string): AbortableRequest<TemplateSingleFragment | null> {
		const request = ApolloUtils.abortableQuery<
			TemplateQuery,
			TemplateSingleFragment | null,
			TemplateQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_TEMPLATE,
				variables: {
					templateId,
				},
			},
			(data) => data.template ?? null,
			this._registerAbort,
		);

		return request;
	}

	public getForTypeAndProduct(
		templateType: TemplateType,
		productId: string | null,
	): AbortableRequest<TemplateSingleFragment | null> {
		const request = ApolloUtils.abortableQuery<
			TemplateForTypeAndProductQuery,
			TemplateSingleFragment | null,
			TemplateForTypeAndProductQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_TEMPLATE_FOR_TYPE_AND_PRODUCT,
				variables: {
					templateType,
					productId,
				},
			},
			(data) => data.findFirstTemplate ?? null,
			this._registerAbort,
		);

		return request;
	}

	public getAll(): AbortableRequest<TemplateListFragment[]> {
		const request = ApolloUtils.abortableQuery<TemplatesQuery, TemplateListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_TEMPLATES_FOR_LIST,
			},
			(data) => data.templates,
			this._registerAbort,
		);

		return request;
	}

	public getAllByType(templateType: TemplateType): AbortableRequest<TemplateListFragment[]> {
		const request = ApolloUtils.abortableQuery<TemplatesForTypeQuery, TemplateListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_TEMPLATES_FOR_TYPE,
				variables: {
					templateType,
				},
			},
			(data) => data.templates,
			this._registerAbort,
		);

		return request;
	}

	public async create(template: TemplateCreateInput): Promise<TemplateSingleFragment> {
		const result = await this._apolloClient.mutate<CreateTemplateMutation, CreateTemplateMutationVariables>({
			mutation: GQL_CREATE_TEMPLATE,
			variables: {
				template,
			},
		});
		if (!result.data?.createOneTemplate) {
			throw new Error('Failed to create template');
		}
		return result.data.createOneTemplate;
	}

	public async update(templateId: string, template: TemplateUpdateInput): Promise<TemplateSingleFragment> {
		const result = await this._apolloClient.mutate<UpdateTemplateMutation, UpdateTemplateMutationVariables>({
			mutation: GQL_UPDATE_TEMPLATE,
			variables: {
				templateId,
				template,
			},
		});
		if (!result.data?.updateOneTemplate) {
			throw new Error('Failed to update template');
		}
		return result.data.updateOneTemplate;
	}

	public async delete(templateIds: string | string[]): Promise<void> {
		templateIds = Array.isArray(templateIds) ? templateIds : [templateIds];

		await this._apolloClient.mutate<DeleteTemplatesMutation, DeleteTemplatesMutationVariables>({
			mutation: GQL_DELETE_TEMPLATES,
			variables: {
				templateIds,
			},
		});
	}
}

export function useTemplateService() {
	const apolloClient = useApolloClient();
	const registerAbort = useAbortRegistry();

	return useMemo(() => new TemplateService(apolloClient, registerAbort), [apolloClient, registerAbort]);
}
