import { useMemo } from 'react';
import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import ICrudService from '@dr-pam/common-components/Services/ICrudService';
import {
	EventListFragment,
	EventSingleFragment,
	EventCreateInput,
	EventUpdateInput,
	CreateEventMutation,
	CreateEventMutationVariables,
	DeleteEventsMutation,
	DeleteEventsMutationVariables,
	EventQuery,
	EventQueryVariables,
	EventsQuery,
	SearchEventsQuery,
	SearchEventsQueryVariables,
	UpdateEventMutation,
	UpdateEventMutationVariables,
	EventWithOccurrencesQuery,
	EventWithOccurrencesQueryVariables,
	EventOccurrenceListFragment,
	EventOccurrenceCreateInput,
	CreateEventOccurrenceMutation,
	CreateEventOccurrenceMutationVariables,
	DeleteEventOccurrencesMutation,
	DeleteEventOccurrencesMutationVariables,
	UpdateEventOccurrenceMutation,
	UpdateEventOccurrenceMutationVariables,
	EventOccurrenceUpdateInput,
	EventCategoryListFragment,
	AddEventToCategoryMutation,
	AddEventToCategoryMutationVariables,
	RemoveEventFromCategoryMutation,
	RemoveEventFromCategoryMutationVariables,
	UpdateEventCategoryIsPinnedMutation,
	UpdateEventCategoryIsPinnedMutationVariables,
	UpdateEventCategoryIsRequiredMutation,
	UpdateEventCategoryIsRequiredMutationVariables,
	UpdateEventCategoryRequirementsMutation,
	UpdateEventCategoryRequirementsMutationVariables,
	EventOccurrenceRegistrationsQuery,
	EventOccurrenceRegistrationsQueryVariables,
} from '../graphql/graphql';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';
import { SortableTreeItem } from '../models/SortableTreeItem';
import hash from 'object-hash';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';

export type EventWithOccurrences = EventSingleFragment & { eventOccurrences: EventOccurrenceListFragment[] };

export class EventSortableTreeItem<T extends EventCategoryListFragment> extends SortableTreeItem<T> {
	public getId(): string {
		return this.item.id;
	}
	public getSortOrder(): number {
		return this.item.sortOrder;
	}
	public setSortOrder(sortOrder: number): void {
		this.item.sortOrder = sortOrder;
	}

	public getName(): string {
		if (this.parent) {
			return `${this.parent.getName()} > ${this.item.event.title}`;
		} else {
			return this.item.event.title;
		}
	}

	public getHash(): string {
		return hash([this.getId(), this.parent?.getId(), this.getSortOrder()]);
	}
}

export const GQL_FRAG_EVENT_OCCURRENCE_LIST = gql`
	fragment EventOccurrenceList on EventOccurrence {
		id
		start
		end
		maxRegistrations
		eventId
		eventRegistrations {
			id
		}
	}
`;

export const GQL_FRAG_EVENT_OCCURRENCE_SINGLE = gql`
	fragment EventOccurrenceSingle on EventOccurrence {
		id
		created
		modified
		start
		end
		maxRegistrations
		eventId
		eventRegistrations {
			id
		}
	}
`;

export const GQL_FRAG_EVENT_LIST = gql`
	fragment EventList on Event {
		id
		title
		slug
		description
		maxRegistrations
	}
`;

export const GQL_FRAG_EVENT_SINGLE = gql`
	fragment EventSingle on Event {
		id
		created
		modified
		title
		slug
		description
		maxRegistrations
	}
`;

export const GQL_FRAG_EVENT_CATEGORY_SINGLE = gql`
	${GQL_FRAG_EVENT_LIST}
	fragment EventCategorySingle on EventCategory {
		id
		isPinned
		sortOrder
		isRequired
		minHours
		minContributions
		contributionName
		event {
			...EventSingle
		}
	}
`;

export const GQL_FRAG_EVENT_CATEGORY_LIST = gql`
	${GQL_FRAG_EVENT_LIST}
	fragment EventCategoryList on EventCategory {
		id
		isPinned
		sortOrder
		isRequired
		minHours
		minContributions
		contributionName
		event {
			...EventList
		}
	}
`;

export const GQL_GET_EVENT = gql`
	${GQL_FRAG_EVENT_SINGLE}
	query Event($eventId: String!) {
		event(where: { id: $eventId }) {
			...EventSingle
		}
	}
`;

export const GQL_GET_EVENT_WITH_OCCURRENCES = gql`
	${GQL_FRAG_EVENT_SINGLE}
	${GQL_FRAG_EVENT_OCCURRENCE_SINGLE}
	query EventWithOccurrences($eventId: String!) {
		event(where: { id: $eventId }) {
			...EventSingle
			eventOccurrences(orderBy: { start: asc }) {
				...EventOccurrenceSingle
			}
		}
	}
`;

export const GQL_GET_EVENTS_FOR_LIST = gql`
	${GQL_FRAG_EVENT_LIST}
	query Events {
		events(orderBy: { created: desc }) {
			...EventList
		}
	}
`;

export const GQL_SEARCH_EVENTS_FOR_LIST = gql`
	${GQL_FRAG_EVENT_LIST}
	query SearchEvents($title: String!) {
		events(orderBy: { title: asc }, where: { title: { contains: $title, mode: insensitive } }) {
			...EventList
		}
	}
`;

export const GQL_GET_ORPHAN_EVENTS = gql`
	${GQL_FRAG_EVENT_LIST}
	query OrphanEvents {
		events(where: { eventCategories: { none: { id: { startsWith: "" } } } }) {
			...EventList
		}
	}
`;

export const GQL_GET_EVENT_USAGES = gql`
	fragment EventUsage on EventCategory {
		id
		category {
			id
			name
			programme {
				id
				name
			}
		}
	}

	query EventUsages($eventId: String!) {
		eventCategories(where: { eventId: { equals: $eventId } }) {
			...EventUsage
		}
	}
`;

export const FQL_FRAG_EVENT_REGISTRATION = gql`
	fragment EventRegistration on EventRegistration {
		id
		userTimezone
		user {
			id
			fullName
			email
		}
	}
`;

export const GQL_GET_EVENT_OCCURRENCE_REGISTRATIONS = gql`
	${GQL_FRAG_EVENT_SINGLE}
	${GQL_FRAG_EVENT_OCCURRENCE_SINGLE}
	${FQL_FRAG_EVENT_REGISTRATION}
	query EventOccurrenceRegistrations($eventOccurrenceId: String!) {
		eventOccurrence(where: { id: $eventOccurrenceId }) {
			...EventOccurrenceSingle
			event {
				...EventSingle
			}
			eventRegistrations {
				...EventRegistration
			}
		}
	}
`;

export const GQL_DELETE_EVENTS = gql`
	mutation DeleteEvents($eventIds: [String!]) {
		deleteManyEvent(where: { id: { in: $eventIds } }) {
			count
		}
	}
`;

export const GQL_CREATE_EVENT = gql`
	${GQL_FRAG_EVENT_SINGLE}
	mutation CreateEvent($event: EventCreateInput!) {
		createOneEvent(data: $event) {
			...EventSingle
		}
	}
`;

export const GQL_UPDATE_EVENT = gql`
	${GQL_FRAG_EVENT_SINGLE}
	mutation UpdateEvent($eventId: String!, $event: EventUpdateInput!) {
		updateOneEvent(where: { id: $eventId }, data: $event) {
			...EventSingle
		}
	}
`;

export const GQL_DELETE_EVENT_OCCURRENCES = gql`
	mutation DeleteEventOccurrences($eventOccurrenceIds: [String!]) {
		deleteManyEventOccurrence(where: { id: { in: $eventOccurrenceIds } }) {
			count
		}
	}
`;

export const GQL_CREATE_EVENT_OCCURRENCE = gql`
	${GQL_FRAG_EVENT_OCCURRENCE_SINGLE}
	mutation CreateEventOccurrence($eventOccurrence: EventOccurrenceCreateInput!) {
		createOneEventOccurrence(data: $eventOccurrence) {
			...EventOccurrenceSingle
		}
	}
`;

export const GQL_UPDATE_EVENT_OCCURRENCE = gql`
	${GQL_FRAG_EVENT_OCCURRENCE_SINGLE}
	mutation UpdateEventOccurrence($eventOccurrenceId: String!, $eventOccurrence: EventOccurrenceUpdateInput!) {
		updateOneEventOccurrence(where: { id: $eventOccurrenceId }, data: $eventOccurrence) {
			...EventOccurrenceSingle
		}
	}
`;

export const GQL_ADD_EVENT_TO_CATEGORY = gql`
	${GQL_FRAG_EVENT_CATEGORY_LIST}
	mutation AddEventToCategory($eventId: String!, $categoryId: String!, $sortOrder: Int) {
		upsertOneEventCategory(
			where: { eventCategory: { eventId: $eventId, categoryId: $categoryId } }
			create: {
				event: { connect: { id: $eventId } }
				category: { connect: { id: $categoryId } }
				sortOrder: $sortOrder
			}
			update: {
				event: { connect: { id: $eventId } }
				category: { connect: { id: $categoryId } }
				sortOrder: { set: $sortOrder }
			}
		) {
			...EventCategoryList
		}
	}
`;

export const GQL_REMOVE_EVENT_FROM_CATEGORY = gql`
	mutation RemoveEventFromCategory($eventId: String!, $categoryId: String!) {
		deleteOneEventCategory(where: { eventCategory: { eventId: $eventId, categoryId: $categoryId } }) {
			id
		}
	}
`;

export const GQL_UPDATE_EVENT_CATEGORY_IS_PINNED = gql`
	mutation UpdateEventCategoryIsPinned($eventCategoryId: String!, $isPinned: Boolean!) {
		updateOneEventCategory(where: { id: $eventCategoryId }, data: { isPinned: { set: $isPinned } }) {
			id
		}
	}
`;

export const GQL_UPDATE_EVENT_CATEGORY_IS_REQUIRED = gql`
	mutation UpdateEventCategoryIsRequired($eventCategoryId: String!, $isRequired: Boolean!) {
		updateOneEventCategory(where: { id: $eventCategoryId }, data: { isRequired: { set: $isRequired } }) {
			id
		}
	}
`;

export const GQL_UPDATE_EVENT_CATEGORY_REQUIREMENTS = gql`
	${GQL_FRAG_EVENT_CATEGORY_LIST}
	mutation UpdateEventCategoryRequirements($eventCategoryId: String!, $eventCategory: EventCategoryUpdateInput!) {
		updateOneEventCategory(where: { id: $eventCategoryId }, data: $eventCategory) {
			...EventCategoryList
		}
	}
`;

export default class EventService
	implements ICrudService<EventListFragment, EventSingleFragment, EventCreateInput, EventUpdateInput>
{
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public get(eventId: string) {
		const request = ApolloUtils.abortableQuery<EventQuery, EventSingleFragment | null, EventQueryVariables>(
			this._apolloClient,
			{
				query: GQL_GET_EVENT,
				variables: {
					eventId,
				},
			},
			(data) => data.event ?? null,
			this._registerAbort,
		);

		return request;
	}

	public getWithOccurrences(eventId: string) {
		const request = ApolloUtils.abortableQuery<
			EventWithOccurrencesQuery,
			EventWithOccurrences | null,
			EventWithOccurrencesQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_EVENT_WITH_OCCURRENCES,
				variables: {
					eventId,
				},
			},
			(data) => data.event ?? null,
			this._registerAbort,
		);
		return request;
	}

	public getAll() {
		const request = ApolloUtils.abortableQuery<EventsQuery, EventListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_EVENTS_FOR_LIST,
			},
			(data) => data.events,
			this._registerAbort,
		);

		return request;
	}

	public getOrphans() {
		const request = ApolloUtils.abortableQuery<EventsQuery, EventListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_ORPHAN_EVENTS,
			},
			(data) => data.events,
			this._registerAbort,
		);

		return request;
	}

	public searchByTitle(title: string) {
		const request = ApolloUtils.abortableQuery<SearchEventsQuery, EventListFragment[], SearchEventsQueryVariables>(
			this._apolloClient,
			{
				query: GQL_SEARCH_EVENTS_FOR_LIST,
				variables: {
					title,
				},
			},
			(data) => data.events,
			this._registerAbort,
		);

		return request;
	}

	public async create(event: EventCreateInput) {
		const result = await this._apolloClient.mutate<CreateEventMutation, CreateEventMutationVariables>({
			mutation: GQL_CREATE_EVENT,
			variables: {
				event,
			},
		});
		if (!result.data?.createOneEvent) {
			throw new Error('Failed to create event');
		}
		return result.data.createOneEvent;
	}

	public async update(eventId: string, event: EventUpdateInput) {
		const result = await this._apolloClient.mutate<UpdateEventMutation, UpdateEventMutationVariables>({
			mutation: GQL_UPDATE_EVENT,
			variables: {
				eventId,
				event,
			},
		});
		if (!result.data?.updateOneEvent) {
			throw new Error('Failed to update event');
		}
		return result.data.updateOneEvent;
	}

	public async delete(eventIds: string | string[]) {
		eventIds = Array.isArray(eventIds) ? eventIds : [eventIds];

		await this._apolloClient.mutate<DeleteEventsMutation, DeleteEventsMutationVariables>({
			mutation: GQL_DELETE_EVENTS,
			variables: {
				eventIds,
			},
		});
	}

	public async createOccurrence(eventOccurrence: EventOccurrenceCreateInput) {
		const result = await this._apolloClient.mutate<
			CreateEventOccurrenceMutation,
			CreateEventOccurrenceMutationVariables
		>({
			mutation: GQL_CREATE_EVENT_OCCURRENCE,
			variables: {
				eventOccurrence,
			},
		});
		if (!result.data?.createOneEventOccurrence) {
			throw new Error('Failed to create event occurrence');
		}
		return result.data.createOneEventOccurrence;
	}

	public getOccurrenceWithRegistrations(eventOccurrenceId: string) {
		const request = ApolloUtils.abortableQuery<
			EventOccurrenceRegistrationsQuery,
			EventOccurrenceRegistrationsQuery['eventOccurrence'],
			EventOccurrenceRegistrationsQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_GET_EVENT_OCCURRENCE_REGISTRATIONS,
				variables: {
					eventOccurrenceId,
				},
			},
			(data) => data.eventOccurrence,
			this._registerAbort,
		);

		return request;
	}

	public async updateOccurrence(eventOccurrenceId: string, eventOccurrence: EventOccurrenceUpdateInput) {
		const result = await this._apolloClient.mutate<
			UpdateEventOccurrenceMutation,
			UpdateEventOccurrenceMutationVariables
		>({
			mutation: GQL_UPDATE_EVENT_OCCURRENCE,
			variables: {
				eventOccurrenceId,
				eventOccurrence,
			},
		});
		if (!result.data?.updateOneEventOccurrence) {
			throw new Error('Failed to update event occurrence');
		}
		return result.data.updateOneEventOccurrence;
	}

	public async deleteOccurrence(eventOccurrenceIds: string | string[]) {
		eventOccurrenceIds = Array.isArray(eventOccurrenceIds) ? eventOccurrenceIds : [eventOccurrenceIds];

		await this._apolloClient.mutate<DeleteEventOccurrencesMutation, DeleteEventOccurrencesMutationVariables>({
			mutation: GQL_DELETE_EVENT_OCCURRENCES,
			variables: {
				eventOccurrenceIds,
			},
		});
	}

	public async addEventToCategory(
		eventId: string,
		categoryId: string,
		sortOrder?: number,
	): Promise<EventCategoryListFragment> {
		const result = await this._apolloClient.mutate<AddEventToCategoryMutation, AddEventToCategoryMutationVariables>(
			{
				mutation: GQL_ADD_EVENT_TO_CATEGORY,
				variables: {
					eventId,
					categoryId,
					sortOrder: sortOrder ?? 0,
				},
			},
		);
		if (!result.data?.upsertOneEventCategory) {
			throw new Error('Failed to add event to category');
		}
		return result.data.upsertOneEventCategory;
	}

	public async removeEventFromCategory(eventId: string, categoryId: string): Promise<void> {
		const result = await this._apolloClient.mutate<
			RemoveEventFromCategoryMutation,
			RemoveEventFromCategoryMutationVariables
		>({
			mutation: GQL_REMOVE_EVENT_FROM_CATEGORY,
			variables: {
				eventId,
				categoryId,
			},
		});
		if (!result.data?.deleteOneEventCategory) {
			throw new Error('Failed to remove event from category');
		}
	}

	public async updateIsPinned(eventCategoryId: string, isPinned: boolean) {
		const result = await this._apolloClient.mutate<
			UpdateEventCategoryIsPinnedMutation,
			UpdateEventCategoryIsPinnedMutationVariables
		>({
			mutation: GQL_UPDATE_EVENT_CATEGORY_IS_PINNED,
			variables: {
				eventCategoryId,
				isPinned,
			},
		});
		if (!result.data?.updateOneEventCategory) {
			throw new Error('Failed to update event category');
		}
		return result.data.updateOneEventCategory;
	}

	public async updateIsRequired(eventCategoryId: string, isRequired: boolean) {
		const result = await this._apolloClient.mutate<
			UpdateEventCategoryIsRequiredMutation,
			UpdateEventCategoryIsRequiredMutationVariables
		>({
			mutation: GQL_UPDATE_EVENT_CATEGORY_IS_REQUIRED,
			variables: {
				eventCategoryId,
				isRequired,
			},
		});
		if (!result.data?.updateOneEventCategory) {
			throw new Error('Failed to update event category');
		}
		return result.data.updateOneEventCategory;
	}

	public async updateRequirements(
		eventCategoryId: string,
		eventCategory: Pick<EventCategoryListFragment, 'minHours' | 'minContributions' | 'contributionName'>,
	) {
		const result = await this._apolloClient.mutate<
			UpdateEventCategoryRequirementsMutation,
			UpdateEventCategoryRequirementsMutationVariables
		>({
			mutation: GQL_UPDATE_EVENT_CATEGORY_REQUIREMENTS,
			variables: {
				eventCategoryId,
				eventCategory: {
					minHours: { set: eventCategory.minHours },
					minContributions: { set: eventCategory.minContributions },
					contributionName: { set: eventCategory.contributionName },
				},
			},
		});
		if (!result.data?.updateOneEventCategory) {
			throw new Error('Failed to update event category');
		}
		return result.data.updateOneEventCategory;
	}
}

export function useEventService() {
	const apolloClient = useApolloClient();
	const registerAbort = useAbortRegistry();

	return useMemo(() => new EventService(apolloClient, registerAbort), [apolloClient, registerAbort]);
}
